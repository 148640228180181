import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/backoffice/login',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice/home',
    name: 'Home',
    component: () => import('../views/Backoffice/BackofficeHome.vue')
  },
  {
    path: '/backoffice/logout',
    name: 'Logout',
    component: () => import('../views/Backoffice/BackofficeLogout.vue')
  },
  {
    path: '/backoffice/backoffice_users',
    name: 'BackofficeUsers',
    component: () => import('../views/Backoffice/BackofficeUsers.vue')
  },
  {
    path: '/backoffice/endusers',
    name: 'BackofficeEndusers',
    component: () => import('../views/Backoffice/BackofficeEndusers.vue')
  },
  {
    path: '/backoffice/wysiwyg',
    name: 'Wysiwyg',
    component: () => import('../views/Backoffice/BackofficeWysiwig.vue')
  },
  {
    path: '/backoffice/forgot_password',
    name: 'BackofficeForgotPassword',
    component: () => import('../views/Backoffice/BackofficeForgotPassword.vue')
  },
  {
    path : '/backoffice/backoffice_user_delete',
    name : 'BackofficeUserDelete',
    component : () => import('../views/Backoffice/BackofficeUserDelete.vue')
  },
  {
    path : '/backoffice/backoffice_user_edit',
    name : 'BackofficeUserEdit',
    component : () => import('../views/Backoffice/BackofficeUserEdit.vue')
  },
  {
    path : '/backoffice/backoffice_login_as_point',
    name : 'BackofficeLoginAsPoint',
    component : () => import('../views/Backoffice/BackofficeLoginAsPoint.vue')
  },
  {
    path : '/backoffice/unit_delete_alert',
    name : 'BackofficeUnitDeleteWarning',
    component : () => import('../views/Backoffice/BackofficeUnitDeleteWarning.vue')
  },
  {
    path : '/backoffice/unit_delete',
    name : 'BackofficeUnitDelete',
    component : () => import('../views/Backoffice/BackofficeUnitDelete.vue')
  },
  {
    path : '/backoffice/users_point',
    name : 'BackofficeUsersPoint',
    component : () => import('../views/Backoffice/BackofficeUsersPoint.vue')
  },
  {
    path : '/backoffice/backoffice_user_change_password',
    name : 'BackofficeUserChangePassword',
    component : () => import('../views/Backoffice/BackofficeUserChangePassword.vue')
  },
  {
    path : '/backoffice/file_uploader',
    name : 'BackofficeFileUploader',
    component : () => import('../views/Backoffice/BackofficeFileUploader.vue')
  },
  {
    path : '/backoffice/cloudinary_file_uploader',
    name : 'BackofficeCloudinaryFileUploader',
    component : () => import('../views/Backoffice/BackofficeCloudinaryFileUploader.vue')
  },
  {
    path : '/backoffice/s3_file_uploader',
    name : 'BackofficeS3FileUploader',
    component : () => import('../views/Backoffice/BackofficeS3FileUploader.vue')
  },
  {
    path : '/backoffice/distribution_points',
    name : 'BackofficeDistributionPoints',
    component : () => import('../views/Backoffice/BackofficeDistributionPoints.vue')
  },
  {
    path : '/backoffice/distribution_point',
    name : 'BackofficeDistributionPoint',
    component : () => import('../views/Backoffice/BackofficeDistributionPoint.vue')
  },
  {
    path : '/backoffice/point_details',
    name : 'BackofficePointDetails',
    component : () => import('../views/Backoffice/BackofficePointDetails.vue')
  },
  {
    path : '/backoffice/point_admin',
    name : 'BackofficePointAdmin',
    component : () => import('../views/Backoffice/BackofficePointAdmin.vue')
  },
  {
    path : '/backoffice/point_management',
    name : 'BackofficePointManagement',
    component : () => import('../views/Backoffice/BackofficePointManagement.vue')
  },
  {
    path : '/backoffice/edit_units',
    name : 'BackofficeEditUnits',
    component : () => import('../views/Backoffice/BackofficeEditUnits.vue')
  },
  {
    path : '/backoffice/edit_boxes',
    name : 'BackofficeEditBoxes',
    component : () => import('../views/Backoffice/BackofficeEditBoxes.vue')
  },
  {
    path : '/backoffice/add_box',
    name : 'BackofficeAddBox',
    component : () => import('../views/Backoffice/BackofficeAddBox.vue')
  },
  {
    path : '/backoffice/edit_box_size',
    name : 'BackofficeEditBoxSize',
    component : () => import('../views/Backoffice/BackofficeEditBoxSize.vue')
  },
  {
    path : '/backoffice/viewing_endusers_admin',
    name : 'BackofficeViewingEndusersAdmin',
    component : () => import('../views/Backoffice/BackofficeViewingEndusersAdmin.vue')
  },
  {
    path : '/backoffice/backoffice_user_packages_admin',
    name : 'BackofficeUserPackagesAdmin',
    component : () => import('../views/Backoffice/BackofficeUserPackagesAdmin.vue')
  },
  {
    path : '/backoffice/backoffice_add_enduser',
    name : 'BackofficeAddEnduser',
    component : () => import('../views/Backoffice/BackofficeAddEnduser.vue')
  },
  {
    path : '/backoffice/backoffice_admin_get_distribution_point_statistics',
    name : 'BackofficeAdminGetDistributionPointStatistics',
    component : () => import('../views/Backoffice/BackofficeAdminGetDistributionPointStatistics.vue')
  },
  {
    path : '/backoffice/statistics',
    name : 'BackofficeStatistics',
    component : () => import('../views/Backoffice/BackofficeStatistics.vue')
  },
  {
    path : '/backoffice/backoffice_user_packages',
    name : 'BackofficeUserPackages',
    component : () => import('../views/Backoffice/BackofficeUserPackages.vue')
  },
  {
    path: '/point',
    name: 'Point',
    component: () => import('../views/Point/Point.vue')
  },
  {
    path: '/point/point_home',
    name: 'PointHome',
    component: () => import('../views/Point/PointHome.vue')
  },
  {
    path: '/point/point_logout',
    name: 'PointLogout',
    component: () => import('../views/Point/PointLogout.vue')
  },
  {
    path: '/point/point_logout',
    name: 'PointLogout',
    component: () => import('../views/Point/PointLogout.vue')
  },
  {
    path: '/point/point_customer',
    name: 'PointCustomer',
    component: () => import('../views/Point/PointCustomer.vue')
  },
  {
    path: '/point/point_delete_customr',
    name: 'PointDeleteCustomer',
    component: () => import('../views/Point/PointDeleteCustomer.vue')
  },
  {
    path: '/point/point_add_customer',
    name: 'PointAddCustomer',
    component: () => import('../views/Point/PointAddCustomer.vue')
  },
  {
    path: '/point/point_packages',
    name: 'PointPackages',
    component: () => import('../views/Point/PointPackages.vue')
  },
  {
    path: '/point/point_new_package',
    name: 'PointNewPackage',
    component: () => import('../views/Point/PointNewPackage.vue')
  },
  {
    path: '/point/point_package_validation',
    name: 'PointPackageValidation',
    component: () => import('../views/Point/PointPackageValidation.vue')
  },
  {
    path : '/point/point_base',
    name : 'PointBase',
    component : () => import('../views/Point/PointBase.vue')
  },
  {
    path : '/backoffice/point_delete',
    name : 'PointDelete',
    component : () => import('../views/Backoffice/PointDelete.vue')
  },
  {
    path : '/backoffice/distribution_point_edit',
    name : 'DistributionPointEdit',
    component : () => import('../views/Backoffice/DistributionPointEdit.vue')
  },
  {
    path : '/backoffice/backoffice_delete_enduser',
    name : 'BackofficeDeleteEnduser',
    component : () => import('../views/Backoffice/BackofficeDeleteEnduser.vue')
  },
  {
    path: '/',
    component : () => import('../views/Hp.vue')
  },
  {
    path: '/support',
    component : () => import('../views/Support.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component : () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
